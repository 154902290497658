import { PublicClientApplication } from "@azure/msal-browser";
import store from '@/store';

export default {
    install: (app, options) => {
        let msalInstance = new PublicClientApplication(
            options
        );

        async function handleResponse(response) {
           console.log("on page load")
            console.log(response)
            let account;
            let isAuthenticated = false;

            if (response !== null) {
                // If there is a response, redirect coming after login. 
                account = response.account;
                isAuthenticated = true;

                store.dispatch("loggingIn", true)

                //verifyrole
            //   await app.config.globalProperties.$FetchRoles('User');


            } // if there is no response, then check to see if user was already logged in
            else {
             console.log("checked for logged in accounts")
                account = await app.config.globalProperties.$IsAuthenticated();
                if (account != null)
                    isAuthenticated = true;

            }
            if (isAuthenticated) {
                await app.config.globalProperties.$AcquireToken(true, account).then(async () => {
                    await store.dispatch("verifyUserAcct", account.username).then(async (response) => {
                 //   console.log(response)
                        //console.log(response.status)
                        console.log(response.response)
                        if (response != false && response.response) {
                          // console.log(response.response.data)
                            if (response.response.data == true) {
                                // await app.config.globalProperties.$AcquireToken(true, account);
                                const loginRequest = {
                                    scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                                    loginHint: account.username,
                                    prompt: "select_account"
                                };
                                try {
                                    let cont = true;
                                   console.log("try ssosilent")
                                    while (cont) {
                                        const loginResponse = await msalInstance.ssoSilent(loginRequest);
                                        // console.log(loginResponse)
                                        if (loginResponse != null) {
                                            account = loginResponse.account;
                                            // console.log(loginResponse.idTokenClaims.extension_Role)
                                            if (loginResponse.idTokenClaims.extension_Role !== undefined) {
                                                cont = false;
                                            }
                                        }
                                    }
                                    await app.config.globalProperties.$AcquireToken(true, account);
                                }
                                catch (err) {
                                    //console.log(err)
                                    // app.config.globalProperties.$SignIn();
                                }

                            }

                        }
                        await app.config.globalProperties.$AcquireToken(false, account).then(async () => {
                            await store.dispatch("setUserLoggedIn", account).then(async () => {
                               console.log("userset Starting token refresh");
                                setInterval(function () {
                                   console.log("setintervaltrigger");
                                    app.config.globalProperties.$refreshToken(false, account);
                                }, 60000);
                               console.log("return 1")
                                return 1;
                            });



                        });


                    })
                })
            }
            // else{
            //     //try to do a silent sign in
            //    console.log("silent sign in")
            //     app.config.globalProperties.$SilentSignIn();
            // }
        }
        msalInstance.handleRedirectPromise().then(handleResponse);

        app.config.globalProperties.$FetchOwnerRole = async (username) => {
            const loginRequest = {
                scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                //scopes: ["openid", "profile", "https://myreservstest.onmicrosoft.com/5a6a9c27-1df6-46a8-8258-75224805b604/consume.api"],
                //scopes: ["openid", "profile", "https://myreservs.onmicrosoft.com/ac04f9f8-0e01-4e95-8e46-617f8d35a50e/consume.api"],
                loginHint: username,
                prompt: "select_account"
            };
            var account;
            try {
                let cont = true;
               console.log("try ssosilent")
                while (cont) {
                    const loginResponse = await msalInstance.ssoSilent(loginRequest);
                   console.log(loginResponse)
                    if (loginResponse != null) {
                        account = loginResponse.account;
                       console.log(loginResponse.idTokenClaims.extension_Role)
                        if (loginResponse.idTokenClaims.extension_Role == "Owner") {
                            cont = false;
                        }
                    }
                }
                await app.config.globalProperties.$AcquireToken(true, account);
            }
            catch (err) {
               console.log(err)
                // app.config.globalProperties.$SignIn();
            }
        }

        // app.config.globalProperties.$SilentSignIn = async () => {
        //     const loginRequest = {
        //         scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
        //         prompt: "none"
        //     };
        //     var account;
        //     try {
          
        //        console.log("try ssosilent")
            
        //             const loginResponse = await msalInstance.ssoSilent(loginRequest);
        //            console.log(loginResponse)
        //             if (loginResponse != null) {
        //                 account = loginResponse.account;
        //             }
          
        //         await app.config.globalProperties.$AcquireToken(true, account);
        //     }
        //     catch (err) {
        //        console.log(err)
        //         // app.config.globalProperties.$SignIn();
        //     }
        // }


        app.config.globalProperties.$VerifyAccount = async (username) => {
            await store.dispatch("verifyUserAcct", username).then(async (response) => {
               console.log(response)
                if (response != false && response.response.data == true) {
                   console.log("login")
                    const loginRequest = {
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        loginHint: username,
                        prompt: "select_account"
                    };
                    try {
                        const loginResponse = await msalInstance.ssoSilent(loginRequest);
                       console.log(loginResponse)
                        if (loginResponse != null) {
                            let account = loginResponse.account;

                            await app.config.globalProperties.$AcquireToken(true, loginResponse).then(async () => {
                                await store.dispatch("setUserLoggedIn", account);
                                setInterval(function () {
                                   console.log("setintervaltrigger"); 
                                    app.config.globalProperties.$refreshToken(false, loginResponse);
                                }, 50000);
                                //  setInterval(app.config.globalProperties.$AcquireToken(false, loginResponse), 50000);
                               console.log("return 1")
                                return 1;
                            });

                        }
                    }
                    catch (err) {
                       console.log(err)
                        //app.config.globalProperties.$SignIn();
                    }


                }
            });
        }

        app.config.globalProperties.$RedirectSignIn = async (redirecturi) => {
           console.log(redirecturi)

            const loginRequest = {
                scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                prompt: "select_account",
                navigateToLoginRequestUrl: false,
                redirectUri: redirecturi
            };
           console.log(msalInstance.config)
            await msalInstance.loginRedirect({ loginRequest });
        }

        app.config.globalProperties.$RedirectSignUp = async (redirecturi) => {
           console.log(redirecturi)

            const loginRequest = {
                scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                prompt: "create",
                navigateToLoginRequestUrl: false,
                redirectUri: redirecturi
            };
           console.log(msalInstance.config)
            await msalInstance.loginRedirect({ loginRequest });
        }


        app.config.globalProperties.$SignIn = async () => {
            // let token;
            // let expiration;
            let account;
            let isAuthenticated = false;
            try {
               console.log("login")

                const loginRequest = {
                    scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                    prompt: "select_account"
                };
                const loginResponse = await msalInstance.loginPopup({ loginRequest });
               console.log(loginResponse)
                isAuthenticated = !!loginResponse.account;
                if (isAuthenticated == true) {
                    account = loginResponse.account;
                    // token = loginResponse.accessToken;
                    // expiration = loginResponse.expiresOn;
                }
                // do something with this?
            } catch (err) {
                // handle error
               console.log(err);

                if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                    try {
                        const passwordResetResponse = await msalInstance.loginPopup({
                            scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                            authority: this.pluginOptions.passwordAuthority,
                            prompt: "select_account"
                        });
                        isAuthenticated = !!passwordResetResponse.account;
                        if (isAuthenticated == true) {
                            account = passwordResetResponse.account;
                            // token = passwordResetResponse.accessToken;
                            // expiration = passwordResetResponse.expiresOn;
                        }
                    } catch (passwordResetError) {
                        console.error(passwordResetError);
                    }
                } else {
                    isAuthenticated = false;
                }

            }
           console.log("account: ")
           console.log(account)
            if (isAuthenticated) {
                await app.config.globalProperties.$AcquireToken(true, account).then(async () => {
                    await store.dispatch("verifyUserAcct", account.username).then(async () => {
                        await app.config.globalProperties.$AcquireToken(true, account).then(async () => {
                            await store.dispatch("setUserLoggedIn", account);
                            setInterval(function () {console.log("setintervaltrigger");
                                app.config.globalProperties.$refreshToken(false, account);
                            }, 50000);
                            //setInterval(app.config.globalProperties.$AcquireToken(false, account), 50000);
                           console.log("return 1")
                            return 1;
                        });
                    })
                })
                // await store.dispatch("setToken",  token);
                // store.dispatch("setExpiration", expiration);
            }
            else {
               console.log("return 0")
                return 0;
            }
        },

            app.config.globalProperties.$SignOutSignIn = async () => {
                var currentAccount = await app.config.globalProperties.$IsAuthenticated();
                await msalInstance.logoutRedirect({
                    account: currentAccount,
                    postLogoutRedirectUri: process.env.VUE_APP_MYRESERVS_URL + "Login",
                }).then(async () => {

                    var redirect = process.env.VUE_APP_MYRESERVS_URL +  window.sessionStorage.getItem("test");
                   console.log(redirect)
                    const loginRequest = {
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        prompt: "select_account",
                        navigateToLoginRequestUrl: false,
                        redirectUri: redirect
                    };
                   console.log(msalInstance.config)
                    await msalInstance.loginRedirect({ loginRequest });
                })

            },

            app.config.globalProperties.$SignOutRedirect = async (path) => {
               let returnUrl = process.env.VUE_APP_MYRESERVS_URL
                if(path!== undefined && path != null && path.length>0){
                    returnUrl += path;
                }
               console.log(returnUrl)
               console.log("signoutredirect")
                await store.dispatch("setUserLoggedOut").then(async () => {
                    var currentAccount = await app.config.globalProperties.$IsAuthenticated();

                    await msalInstance.logoutRedirect({
                        account: currentAccount,
                        postLogoutRedirectUri:  returnUrl,
                        authority: process.env.VUE_APP_B2C_AUTHORITY,
                    });

                });
            },

            app.config.globalProperties.$SignOut = async () => {
               console.log("signout")
                await store.dispatch("setUserLoggedOut").then(async () => {
                    var currentAccount = await app.config.globalProperties.$IsAuthenticated();

                    await msalInstance.logoutPopup({
                        account: currentAccount,
                        postLogoutRedirectUri: process.env.VUE_APP_MYRESERVS_URL,
                        mainWindowRedirectUri: process.env.VUE_APP_MYRESERVS_URL
                    });

                });
            },
            app.config.globalProperties.$AcquireToken = async (force, account) => {
               console.log("Acquire Token, force:", force);
               console.log("Acquire Token, account:", account);
                // var timeLeft = (store.state.expiration - new Date().getTime() / 1000);
                var timeLeft = new Date() - store.state.expiration;
                //var diffDays = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));
             
                
                //    var now = new Date();

                //     var timeLeft = new Date(now.getTime() +store.state.expiration * 1000);

                let acquire = timeLeft <= 1000 || isNaN(timeLeft) || store.state.accessToken == null || store.state.expiration == null;
               console.log("timeLeft: " + timeLeft)
               console.log("expiration: " + store.state.expiration)
               console.log("acquire: " + acquire)
                let request;
                if (account != null) {
                    request = {
                        account: account,
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        forceRefrsh: force,
                    };
                }
                else {
                    request = {
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        forceRefrsh: force,
                    };
                }
               console.log(request)
                try {
                    //if signed in and if token not close to expire
                    if (acquire || force) {
                        await msalInstance.acquireTokenSilent(request).then(async (response) => {
                           console.log(response)
                            if (response.accessToken.length > 0) {

                                await store.dispatch("setToken", response.accessToken);
                                await store.dispatch("setExpiration", response.expiresOn);
                            }
                            else {
                               console.log("access token not retrieved")

                                // await msalInstance.logout();
                                store.dispatch("setUserLoggedOut");
                            }
                        });

                    }
                } catch (error) {
                    if (error) {
                       console.log("error")
                       console.log(error)
                        //app.config.globalProperties.$SignIn();
                        // store.dispatch("setUserLoggedOut");
                    }
                    return false;
                }

            },

            app.config.globalProperties.$refreshToken = (force, account) => {
               console.log("REFRESH TOKEN");
               console.log("Acquire Token, force:", force);
               console.log("Acquire Token, account:", account);

                var timeLeft = store.state.expiration - new Date();



                let acquire = timeLeft <= 120000 || isNaN(timeLeft) || store.state.accessToken == null || store.state.expiration == null;
               console.log("timeLeft: " + timeLeft)
               console.log("expiration: " + store.state.expiration)
               console.log("acquire: " + acquire)
                let request;
                if (account != null) {
                    request = {
                        account: account,
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        forceRefrsh: force,
                    };
                }
                else {
                    request = {
                        scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        forceRefrsh: force,
                    };
                }
               console.log(request)
                try {
                    //if signed in and if token not close to expire
                    if (acquire || force) {
                        msalInstance.acquireTokenSilent(request).then(async (response) => {
                           console.log(response)
                            if (response.accessToken.length > 0) {

                                await store.dispatch("setToken", response.accessToken);
                                await store.dispatch("setExpiration", response.expiresOn);
                            }
                            else {
                               console.log("access token not retrieved")

                                // await msalInstance.logout();
                                store.dispatch("setUserLoggedOut");
                            }
                        });

                    }
                } catch (error) {
                    if (error) {
                       console.log("error")
                       console.log(error)
                        //app.config.globalProperties.$SignIn();
                        // store.dispatch("setUserLoggedOut");
                    }
                    return false;
                }

            },

            app.config.globalProperties.$IsAuthenticated = async () => {
               console.log("checking if authenticated")
                try {
                    let acts = msalInstance.getAllAccounts();
                   console.log(acts)
                    if (acts && acts.length > 0) {
                       console.log("authenticated")
                       console.log(acts[0]);
                        return acts[0];
                    }
                    else {
                       console.log("not authenticated")
                        store.dispatch("setUserLoggedOut");
                        return null;
                    }
                }
                catch (error) {
                   console.log("error in authentication check")
                   console.log(error)
                }
            }

        app.config.globalProperties.$FetchRoles =
            async (role) => {
                var account = await app.config.globalProperties.$IsAuthenticated();
               console.log(account);
                let request = {
                    account: account,
                    scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                    forceRefrsh: true,
                };
                msalInstance.acquireTokenSilent(request).then(async (response) => {
               //    console.log(response);

                    try {
                        const loginRequest = {
                            scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                            loginHint: account.username,
                            prompt: "none"
                        };

                        let cont = true;
                    //   console.log(loginRequest)
                      // console.log("fetching roles")
                        while (cont) {
                       //    console.log("attempt")
                            const loginResponse = await msalInstance.ssoSilent(loginRequest);
                         //  console.log(loginResponse)
                            if (loginResponse != null) {
                                account = loginResponse.account;
                          //     console.log(loginResponse.idTokenClaims.extension_Role)
                                if (loginResponse.idTokenClaims.extension_Role !== undefined) {
                                    if (loginResponse.idTokenClaims.extension_Role == role || loginResponse.idTokenClaims.extension_Role.includes("MR")) {
                                        cont = false;
                                        return 1;
                                    }
                                }
                            }
                        }
                        // await app.config.globalProperties.$AcquireToken(true, account);


                    }
                    catch (err) {
                       console.log(err)
                        return 0;
                        // let cont = true;
                        // let redirectRequest = {
                        //     account: account,
                        //     scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        //     forceRefrsh: true,

                        // };
                        // while (cont) {
                        //     await msalInstance.acquireTokenRedirect(redirectRequest).then(async (loginResponse) => {
                        //        console.log(loginResponse)
                        //         if (loginResponse != null) {
                        //             account = loginResponse.account;
                        //            console.log(loginResponse.idTokenClaims.extension_Role)
                        //             if (loginResponse.idTokenClaims.extension_Role !== undefined) {

                        //                 if (loginResponse.idTokenClaims.extension_Role == role || loginResponse.idTokenClaims.extension_Role.includes("MR")) {
                        //                     cont = false;
                        //                 }
                        //             }
                        //         }
                        //     });
                        // }

                        
                        //  if (!last) {
                        //     const loginRequest = {
                        //         scopes: ["openid", "profile", process.env.VUE_APP_B2C_SCOPE],
                        //         prompt: "select_account",
                        //         navigateToLoginRequestUrl: false,
                        //     };
                        //    console.log(msalInstance.config)
                        //     await msalInstance.loginRedirect({ loginRequest }).then(async (response) => {
                        //        console.log(response)
                        //app.config.globalProperties.$FetchRoles(role, false);

                        //     });
                        //  }

                    }
                });


            }

            app.config.globalProperties.$VerifyRole =
            async (role) => {
               console.log("Verify Role");
                var account = await app.config.globalProperties.$IsAuthenticated();
               console.log(account);
                if (account.idTokenClaims.extension_Role !== undefined) {
                    if (account.idTokenClaims.extension_Role == role || account.idTokenClaims.extension_Role.includes("MR")) {
                        return 1;
                    }
                    else{
                        return 0;
                    }
                }
                else{
                    return 0;
                }
            }
    }
}